<template>
  <div>
    <div class="search-container">
      <div v-if="selectedRowsIds.length > 0">
        <el-dropdown>
          <button class="btn btn-primary" type="button">
            <i class="fa fa-warning"></i> Bulk Actions
          </button>
          <el-dropdown-menu slot="dropdown">
            <a @click="openBulkSendNotification()">
              <el-dropdown-item command="view">
                <i class="fa fa-envelope-o"></i>Send
                Notifications</el-dropdown-item
              >
            </a>
            <a @click="openBulkWASendNotification()">
              <el-dropdown-item command="view">
                <i class="el-icon-chat-dot-round"></i>Send Notifications
                (WA)</el-dropdown-item
              >
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-date-picker
        v-model="dateRangeFilter"
        type="daterange"
        range-separator="-"
        start-placeholder="Start date"
        end-placeholder="End date"
        size="small"
        :format="'MM-dd-yyyy '"
        @change="getFilteredData('slotDate')"
        class="mr-1"
      >
      </el-date-picker>
      <el-select
        v-if="isMissingTimesheetData"
        v-model="selectedFrequencies"
        placeholder="Filter by Timesheet Frequency"
        size="small"
        multiple
        collapse-tags
        clearable
        @change="getFilteredData('timesheet_frequency')"
        class="mr-1"
      >
        <el-option
          v-for="item in getFrequencyOptions"
          :key="item.value"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <el-select
        v-if="isMissingTimesheetData"
        v-model="selectedProjects"
        placeholder="Filter by Project"
        size="small"
        multiple
        collapse-tags
        clearable
        filterable
        @change="getFilteredData('project')"
        class="mr-1"
        :loading="entityDataLoading"
        loading-text="Loading..."
      >
        <el-option
          v-for="item in finalEntityDatas"
          :key="item._id"
          :label="item.value"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        placeholder="Search"
        size="small"
        v-model="search_string"
        clearable
        prefix-icon="el-icon-search"
        style="width: 300px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          size="mini"
          @click="search"
        ></el-button>
      </el-input>
    </div>
    <el-table
      :data="result_data"
      style="width: 100%"
      class="vue-data-table-default"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :width="250"
        v-for="(step, index) of columns"
        :label="step.label"
        :key="step.key + index"
        :prop="step.key"
        sortable
      >
        <template slot-scope="scope">
          {{ getSlotDateValue(scope.row[step.key], step.key) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="action"
        label="Actions"
        class="action"
        min-width="170"
        fixed="right"
      >
        <template slot-scope="scope">
          <ul class="action-buttons">
            <li v-for="(action, index) in actions" :key="'action_' + index">
              <button class="btn btn-light btn-sm m-lr-1" type="button">
                <span
                  class="text-muted"
                  title="Add"
                  @click="executeAction(scope.row, action)"
                  ><i class="fa fa-plus-circle"></i
                ></span>
              </button>
              <button
                class="btn btn-light btn-sm m-lr-1"
                type="button"
                v-if="!isApplicationUserSide"
              >
                <span
                  class="text-muted"
                  title="Send Notification"
                  @click="openSendNotification(scope.row)"
                  ><i class="fa fa-envelope-o"></i
                ></span>
              </button>
              <button
                class="btn btn-light btn-sm m-lr-1"
                type="button"
                v-if="!isApplicationUserSide"
              >
                <span
                  class="text-muted"
                  title="Send Notification (WA)"
                  @click="openWASendNotification(scope.row)"
                  ><i class="el-icon-chat-dot-round"></i
                ></span>
              </button>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <dialog-component
      title="Bulk Notification (selected data)"
      :visible="bulkSendNotificationDialog"
      v-loading="emailConfigLoading"
      element-loading-text="Please wait while we process your request."
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedRowsIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      title="Bulk WA Notification (selected data)"
      :visible="bulkSendNotificationWADialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationWADialog = false"
      :isShowFooter="false"
    >
      <WAConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedRowsIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </WAConfig>
    </dialog-component>
    <dialog-component
      title="Send Notification"
      :visible="sendNotificationDialog"
      v-loading="emailConfigLoading"
      element-loading-text="Sending the mail, please wait..."
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      title="Send WA Notification"
      :visible="sendWANotificationDialog"
      v-loading="emailConfigLoading"
      element-loading-text="Sending the message, please wait..."
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendWANotificationDialog = false"
      :isShowFooter="false"
    >
      <WAConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      >
      </WAConfig>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
// import moment from "moment";

import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import { fetchEntityById } from "@/repo/entityRepo";
export default {
  data() {
    return {
      search_string: "",
      result_data: [],
      selectedRowsIds: [],
      bulkSendNotificationDialog: false,
      bulkSendNotificationWADialog: false,
      emailConfigLoading: false,
      paramsData: {},
      currentEntity: null,
      sendNotificationDialog: false,
      sendWANotificationDialog: false,
      selectedNotifyEntity: null,
      selectedFrequencies: [],
      dateRangeFilter: [],
      finalEntityDatas: [],
      selectedProjects: [],
      entityDataLoading: false,
    };
  },
  mixins: [TemplateBuilderhelper, NavigationHelper],
  components: {
    EmailConfig: () => import("@/components/notifications/emailConfig.vue"),
    WAConfig: () => import("@/components/notifications/WAConfig.vue"),
  },
  props: {
    data: Array,
    columns: Array,
    actions: Array,
    component: Object,
    getAllEntities: Array,
    getAllCompanyTemplatesData: Object,
    entity_id: String,
    isApplicationUserSide: Boolean,
  },
  mounted() {
    this.$store.commit("templates/setSuggestionFormData", null, {
      root: true,
    });
    this.setCurrentEntity();
    this.search();
    if (this.isMissingTimesheetData) {
      this.getProjectEntityOptions();
    }
  },
  computed: {
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("entities", [
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    applicationUserFormbuilderMenuItems() {
      if (this.getEntityMenu?.menu_list) {
        let menuItems = this.getEntityMenu.menu_list.filter(
          (menu) =>
            menu?.feature == "form_builder" &&
            menu?.formbuilder_id?.steps.find((step) => step?.entity)
        );
        return menuItems;
      }
      return null;
    },
    getFrequencyOptions() {
      let frequencyField = this.columns.find((f) =>
        f?.key?.includes("timesheet_frequency")
      );
      return frequencyField?.list_data;
    },
    isMissingTimesheetData() {
      return this.component?.component_name == "Missing Timesheet"
        ? true
        : false;
    },
  },
  methods: {
    getSlotDateValue(row, key) {
      let value = row?.name || row?.value;
      if (key == "slotDate") {
        let finalValue = row.value.split(" - ")[1];
        let [month, day, year] = finalValue.split("-");
        let previousDate = new Date(`${year}-${month}-${day}`);
        previousDate.setDate(previousDate.getDate() - 1);
        let formattedDate = `${String(previousDate.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(previousDate.getDate()).padStart(
          2,
          "0"
        )}-${previousDate.getFullYear()}`;
        value = row.value.replace(finalValue, formattedDate);
      }
      return value;
    },
    formatDate(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      let year = date.getFullYear();
      return `${month}-${day}-${year}`;
    },
    getFilteredData(key) {
      if (
        key == "slotDate" &&
        this.dateRangeFilter &&
        !this.selectedFrequencies?.length
      ) {
        let formattedRange = `${this.formatDate(
          this.dateRangeFilter[0]
        )} - ${this.formatDate(this.dateRangeFilter[1])}`;
        let formattedStartDate = new Date(this.dateRangeFilter[0]);
        let formattedEndDate = new Date(this.dateRangeFilter[1]);

        let filteredData = this.data.filter((dt) => {
          let slotDateRange = dt["slotDate"].value.split(" - ");
          if (slotDateRange?.length == 2) {
            let slotStartDate = new Date(slotDateRange[0]);
            let slotEndDate = new Date(slotDateRange[1]);
            return (
              formattedRange == dt["slotDate"].value ||
              (formattedStartDate <= slotStartDate &&
                formattedEndDate >= slotEndDate)
            );
          }
          return false;
        });

        let field = this.columns.find((f) => key == f.key.split("#")[1]);
        if (this.selectedFrequencies?.length > 0) {
          filteredData = filteredData.filter(
            (dt) =>
              dt[field?.key] &&
              dt[field.key].value &&
              this.selectedFrequencies.includes(dt[field.key].value)
          );
        }
        this.result_data = filteredData;
      } else if (
        key == "timesheet_frequency" &&
        this.selectedFrequencies?.length > 0 &&
        !this.dateRangeFilter?.length
      ) {
        let field = this.columns.find((f) => key == f?.key?.split("#")?.[1]);
        let filteredData = this.data.filter(
          (dt) =>
            dt[field?.key] &&
            dt[field.key].value &&
            this.selectedFrequencies.includes(dt[field.key].value)
        );
        this.result_data = filteredData;
      } else if (
        key == "project" &&
        !this.selectedFrequencies?.length &&
        !this.dateRangeFilter?.length
      ) {
        let field = this.columns.find((f) => key == f.key.split("#")[1]);
        let filteredData = this.data.filter(
          (dt) =>
            dt[field.key] &&
            dt[field.key].name &&
            this.selectedProjects.includes(dt[field.key].name)
        );
        this.result_data = filteredData;
      } else if (
        (this.dateRangeFilter?.length > 0 &&
          this.selectedFrequencies?.length > 0) ||
        (this.dateRangeFilter?.length > 0 &&
          this.selectedProjects?.length > 0) ||
        (this.selectedFrequencies?.length > 0 &&
          this.selectedProjects?.length > 0) ||
        (this.dateRangeFilter?.length > 0 &&
          this.selectedFrequencies?.length > 0 &&
          this.selectedProjects?.length > 0)
      ) {
        let formattedRange = `${this.formatDate(
          this.dateRangeFilter?.[0]
        )} - ${this.formatDate(this.dateRangeFilter?.[1])}`;
        let formattedStartDate = new Date(this.dateRangeFilter[0]);
        let formattedEndDate = new Date(this.dateRangeFilter[1]);
        let filteredData = this.data.filter((dt) => {
          let slotDateRange = dt["slotDate"].value.split(" - ");
          if (slotDateRange.length === 2) {
            let slotStartDate = new Date(slotDateRange[0]);
            let slotEndDate = new Date(slotDateRange[1]);
            return (
              formattedRange === dt["slotDate"].value ||
              (formattedStartDate <= slotStartDate &&
                formattedEndDate >= slotEndDate)
            );
          }
          return false;
        });
        if (key !== "slotDate" && key.includes("frequency")) {
          let field = this.columns.find((f) => key == f.key.split("#")[1]);
          filteredData = filteredData.filter(
            (dt) =>
              dt[field.key] &&
              dt[field.key].value &&
              this.selectedFrequencies.includes(dt[field.key].value)
          );
        }
        if (key !== "slotDate" && key == "project") {
          let field = this.columns.find((f) => key == f.key.split("#")[1]);
          filteredData = filteredData.filter(
            (dt) =>
              dt[field.key] &&
              dt[field.key].name &&
              this.selectedProjects.includes(dt[field.key].name)
          );
        }
        this.result_data = filteredData;
      } else {
        this.result_data = this.data;
      }
    },

    async getProjectEntityOptions() {
      this.entityDataLoading = true;
      let projectField = this.columns.find((f) => f.key.includes("project"));
      let projectEntity = await fetchEntityById(projectField?.entity_id);
      let projectTemplate = projectEntity.templates.find((t) =>
        t?.templateInfo?.slug?.includes("project")
      );

      let params = {
        entity_id: projectField?.entity_id,
        filters: [],
        relationship: {},
        limit: 50,
        page: 1,
        ignore_populate: true,
      };
      try {
        const response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (response && response.data) {
          let entityData = response.data;
          entityData.forEach((data) => {
            if (
              projectTemplate?.template_id &&
              data?.entityData?.[projectTemplate.template_id]?.["project"]
            ) {
              let value =
                data.entityData[projectTemplate.template_id]["project"];
              this.finalEntityDatas.push({
                _id: data._id,
                value: value,
              });
            }
          });
        }
        this.entityDataLoading = false;
      } catch (error) {
        this.entityDataLoading = false;
        console.log("getProjectEntityOptions", error);
      }
    },
    async openSendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = true;
      this.sendWANotificationDialog = false;
    },
    async openWASendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = true;
    },
    async setCurrentEntity() {
      this.currentEntity = await fetchEntityById(this.entity_id);
    },
    async closeBulkSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.bulkSendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending mails initiated",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Sending whatsapp message",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.paramsData = {};
      this.emailConfigLoading = false;
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = false;
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch(
        "entities/sendNotificationToEntityDatausers",
        params
      );
      if (this.getSentEntityDataNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.sendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending mail(s) initiated",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Sending whatsapp message",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message:
            this.getSentEntityNotificationStatusErrors ||
            "Error while sending mails",
          type: "error",
        });
      }
      this.paramsData = {};
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = false;
    },
    openBulkSendNotification() {
      this.bulkSendNotificationDialog = true;
      this.bulkSendNotificationWADialog = false;
    },
    openBulkWASendNotification() {
      this.bulkSendNotificationDialog = false;
      this.bulkSendNotificationWADialog = true;
    },
    handleSelectionChange(checkedDocs) {
      this.selectedRowsIds = checkedDocs.map((e) => e._id);
    },
    search() {
      if (this.search_string) {
        this.result_data = [...this.data].filter((row) => {
          return this.columns.find(
            (col) =>
              (row[col.key]?.name &&
                row[col.key].name
                  .toLowerCase()
                  .includes(this.search_string.toLowerCase())) ||
              (row[col.key]?.value &&
                row[col.key].value
                  .toLowerCase()
                  .includes(this.search_string.toLowerCase()))
          )
            ? true
            : false;
        });
      } else {
        this.result_data = [...this.data];
      }
    },
    generateSuggestionForm(entity, otherInfo, row) {
      let form = {};
      (entity?.templates || []).forEach((temp) => {
        let template = (this.getAllCompanyTemplatesData?.data || []).find(
          (e) => e._id == temp.template_id
        );
        if (template?.sections?.[0]?.fields && otherInfo) {
          let tempObj = {};
          template.sections[0].fields.map((field) => {
            let key =
              field && field.key.includes("#")
                ? field.key.split("#")[1]
                : field.key;
            if (field.inputType == "ENTITY") {
              if (otherInfo.entity_id == field.entity_id) {
                tempObj[key] = row._id;
              }
            } else if (field.input_type == "DATE") {
              tempObj[key] = new Date(
                row.slotDate.value.split(" - ")[0]
              ).toISOString();
              // if (
              //   otherInfo?.other_entity_date &&
              //   otherInfo.other_entity_date.includes("#") &&
              //   otherInfo.other_entity_date.split("#")[1] == key
              // ) {
              //   tempObj[key] = moment(row.slotDate.value.split("-")[0])
              //     .startOf("day")
              //     .toISOString();
              // }
            }
          });
          form[temp.template_id] = tempObj;
        }
      });
      return form;
    },
    executeAction(row, action) {
      let suggestionForm = this.generateSuggestionForm(
        this.getAllEntities?.find((e) => action?.entity_id == e?._id),
        action.other_info,
        row
      );
      this.$store.commit("templates/setSuggestionFormData", suggestionForm, {
        root: true,
      });
      if (action.type == "ADD") {
        if (this.isApplicationUserSide) {
          let item = (this.applicationUserFormbuilderMenuItems || []).find(
            (e) =>
              (e?.formbuilder_id?.steps || []).find(
                (s) =>
                  s.entity == this.component?.other_entity_id.split("#")?.[0]
              )
          );
          if (item?.formbuilder_id?._id) {
            this.$router.push({
              path: `/ap/fbd/${item.formbuilder_id.code}/${item.formbuilder_id.steps[0].slug}/${item.formbuilder_id._id}?user_id=${this.getAuthenticatedUser._id}`,
            });
          }
        } else {
          this.openEntityDataScreen(action.entity_id, "", "", "fromCustom");
        }
      } else if (action.type == "FORM_ADD") {
        this.$router.push({
          path: action.url,
          query: {
            fromCustom: true,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  /* width: 100%; */
}
</style>
