import { render, staticRenderFns } from "./CustomTable.vue?vue&type=template&id=603aa85c&scoped=true"
import script from "./CustomTable.vue?vue&type=script&lang=js"
export * from "./CustomTable.vue?vue&type=script&lang=js"
import style0 from "./CustomTable.vue?vue&type=style&index=0&id=603aa85c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "603aa85c",
  null
  
)

export default component.exports